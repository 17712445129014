@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900&display=swap');

/*Utilities Reset*/
@layer utilities {
  @variants hover, focus {
    .filter-none {
      filter: none;
    }
    .filter-grayscale {
      filter: grayscale(100%);
    }
  }
}
/*Base Reset*/
@layer base {
	body {
    font-family: 'Montserrat', sans-serif;
    font-size: 110%;
    overflow-x: hidden;
    @apply text-gray-900;
	}
 	h1 {
    @apply text-4xl;
  }
  h2 {
    	@apply text-2xl;
  }
  .container {
    width: 80vw !important;
  }
  .hero a {
  	@apply bg-opacity-50 bg-gradient-to-r from-primary to-blue-300 py-6 px-24 text-lg
  }
  .bottom-image {
    bottom: -2px;
  }
  .hero {
    @apply h-screen
  }
  /*.bg-gradient {
    @apply bg-opacity-50 bg-gradient-to-b from-gray-900 to-primary
  }*/
  .bg-gradient:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-image: linear-gradient(-250deg, #222EFF 13%, rgba(45,56,255,0.00) 100%);
  }
  .bg-transportador, .bg-expedidor {
    @apply filter-grayscale opacity-50
  }
}
.tox .tox-edit-area__iframe {
  background-color: #f2f2f2 !important;
}
.dropdown:hover .dropdown-menu {
  display: block;
}
.logo, .logo-white {
  fill: white;
}
.logo-login {
  fill: #1E2AFF;
}
.logo-primary {
  fill: #1E2AFF;
}
.border-1 {
  border-width: 1px;
}
body, html {
  overflow-x: hidden;
}
#homepage ul, #homepage ol {
  list-style-type: none !important;
  margin-left: 0 !important;
}
ol {
  list-style: decimal !important;
  margin-left: 2em;
}
ul {
  list-style-type: circle !important;
  margin-left: 2em;
}

.mce-content-body {
  padding: 10px;
}
.h-half-screen {
  height: 65vh;
}

/*grid framework*/
.gap-sm {
  grid-gap: 1em;
}
.dimm {
  opacity: .2;
}
a.btn-primary {
  margin-top: 1em;
  padding: 1.2em 4em;
  color: white;
  font-size: 18px;
  background-image: linear-gradient(-269deg, rgba(30,42,248,.9) 0%, rgba(30,189,255,.9) 100%);
}
a.btn-primary:hover {
  background-image: linear-gradient(-269deg, rgba(30,42,248,1) 0%, rgba(30,189,255,1) 100%);
  text-decoration: none;
}
.dark-gradient::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(2,0,31);
  background: linear-gradient(0deg, rgba(2,0,31,1) 0%, rgba(0,1,1,0) 100%);
}
.dark-gradient-rev::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(2,0,31);
  background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5032387955182073) 100%);
}
.arrow-shape {
    clip-path: polygon(0 0, 100% 0, 100% 90%, 50% 100%, 0 90%);
  }
@media screen and (min-width: 769px){
  .arrow-shape {
    clip-path: polygon(0 0, 100% 0, 100% 80%, 50% 100%, 0 80%);
  }
}
.bg-gray-texture {
  background: url('/images/bg_light_pattern.png') repeat;
}
.logo {
  position: relative;
  top: 10px;
  fill: white;
}
.logo-primary {
  fill: #1E2AFF;
}
.fill-white {
  fill: white;
}
#header {
  transition: all .5s;
}
.nav-link {
  color: white;
  font-size: 16px;
}
.header-fixed {
  background: white !important;
  box-shadow: 0 0 12px 0 rgba(42,42,42,0.21);  
}
#header.header-fixed a.menu-link {
  color: #333;
}
#header.header-fixed #mobile-menu a {
  color: #fff !important;
}
.truck {
  /*transition: all .8s;*/
  bottom: initial;
  transform: translateX(-500%);
  z-index: 1;
  position: relative;
  justify-self: end;
}
@media screen and (min-width: 769px) {
  #box-transportador {
    /*opacity: 0;
    transform: translateX(-300%);*/
    z-index: 1;
    /*transition: all .8s;*/
  }
  #box-expedidor {
   /* opacity: 0;
    transform: translateX(300%);*/
    z-index: 1;
    /*transition: all .8s;*/
  }
}
.feature-icon {
  stroke: linear-gradient(-269deg, rgba(30,42,248,.9) 0%, rgba(30,189,255,.9) 100%) !important;
}
.splide__pagination {
  position: relative !important;
}
.splide__pagination__page.is-active {
  background: #333 !important;
}
.circle-chart__circle {
  display: block;
    animation: circle-chart-fill 1s reverse;
    transform: rotate(-180deg);
    transform-origin: center;
}
@keyframes circle-chart-fill {
  to { stroke-dasharray: 0 100; }
}
.stats-container {
  top: 65px;
  left: 0;
  width: 100%;
}
/*.testemunho::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('/images/quotes.svg') no-repeat;
  background-size: 25% 25%;
  background-position: 25% center;
  opacity: .1;
}*/
.arrow:before {
  content: '\21e2';
  position: relative;
  width: 100%;
  font-size: 1.5em;
  text-align: center;
}
.circle{
  width: 22vmin;
  height: 22vmin;
  border: 1px solid #CFD3FD;
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.mobile-menu {
  transition: all .2s;
}
